export class Project {
    constructor(data, container) {
        this.resize(data, container);
    }

    initHTML = (data, container) => {
        let project = document.createElement('span');
        project.classList.add('project');

        container.appendChild(project);
        this.initTitle(project, data);
    }

    initTitle = (wrapper, data) => {
        let tWrapper = document.createElement('a');
        tWrapper.classList.add('tWrapper');

        let i = data.index;
        let title = document.createElement('span');
        title.classList.add('title');
        title.innerHTML = `<span class="bg"></span><span class="underline"></span><span class="title-link">${ (window.innerWidth < 1024 ? '/ ' : '') + data.title}</span><span class="company">(${data.company})</span>`;
        tWrapper.appendChild(title);

        if (i === 1 || i === 3 || i == 5 || i == 7) {
            let slash = document.createElement('span');
            slash.classList.add('slash');
            slash.innerHTML = ' / '
            tWrapper.appendChild(slash);
        }

        if (data.url.includes('http')) {
            tWrapper.setAttribute('href', data.url);
            tWrapper.setAttribute('target', '_blank');
        } else {
            let video = data.videoWrapper.querySelector('video');
            title.addEventListener('click', () => {
                video.setAttribute('src', `../videos/${data.url}`);

                setTimeout(() => {
                    data.videoWrapper.classList.add('visible');
                }, 100);
            });
        }


        // window.setTimeout(() => {
        //     title.classList.add('visible');
        // }, 290 + Project.data.index * 115);

        wrapper.appendChild(tWrapper);

        let width = title.querySelector('.title-link').getBoundingClientRect().width;
        title.querySelector('.underline').style.width = width + 'px';
    }

    /**
    * Render loop
    */
    loop = _ => {}

    /**
    * Event listeners
    */
    resize = (data, container) => {
        this.initHTML(data, container);
    }
}