import '@scss/index.scss';

import { Project } from '@components/project.js'

export class Main {
    static container;


    constructor() {
        
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
        Main.Projects = new Array();
        Main.string = '';

        this.initMain();
    }

    initPreloader = _ => {
        Main.preloader = document.createElement('div');
        Main.preloader.classList.add('preloader');

        let title = document.createElement('strong');
        title.classList.add('preloader-title');
        title.innerHTML = 'Dion Pieters'

        let titleMask = document.createElement('span');
        titleMask.classList.add('preloader-title-mask');

        titleMask.appendChild(title);
        Main.preloader.appendChild(titleMask);
        document.body.appendChild(Main.preloader);

        window.setTimeout(_ => {
            Main.preloader.classList.add('out');

            window.setTimeout(() => {
                document.body.removeChild(Main.preloader);
            }, 1250);
        }, 1750);
    }

    initMain = async _ => {
        this.initPreloader();

        this.initHTML();
        this.initAbout();
        this.initSocials();
        this.initProjects();
    }

    initHTML = _ => {
        Main.container = document.createElement('main');
        Main.container.classList.add('main');

        window.setTimeout(() => {
            Main.container.classList.add('in');
        }, 32)

        document.body.appendChild(Main.container);
    }

    initProjects = _ => {
        const wrapper = document.createElement('div');
        wrapper.classList.add("projects");
        wrapper.innerHTML = '<strong>Projects </strong>';

        const videoWrapper = document.createElement('div');
        videoWrapper.classList.add("videowrapper");

        videoWrapper.innerHTML = '<div class="bg"></div><div class="close">Close</div><video autoplay loop playsinline></video>'
        Main.container.appendChild(videoWrapper);

        let video = videoWrapper.querySelector('video');
        videoWrapper.querySelector('.bg').addEventListener('click', () => {
            videoWrapper.classList.remove('visible');
            setTimeout(() => {
                video.setAttribute('src', '');
            }, 650)
        })

        videoWrapper.querySelector('.close').addEventListener('click', () => {
            videoWrapper.classList.remove('visible');
            setTimeout(() => {
                video.setAttribute('src', '');
            }, 650)
        })

        Main.container.appendChild(wrapper);

        let projects = [
            { title: 'Frans Hals Museum', company: 'BIA', url: 'https://www.franshalsmuseum.nl/en/' },
            { title: 'Studio Job', company: 'BIA', url: 'https://www.studio-job.com/' },
            { title: 'Powerhouse Company', company: 'BIA', url: 'https://www.powerhouse-company.com/' },
            { title: 'Max Niblock', company: 'Freelance', url: 'https://clockstrikestwelve.com/' },
            { title: 'Airforce Puzzle', company: 'AT', url: 'https://airforcepuzzle.com/' },
            { title: 'Spotify Wrapped 2020', company: 'AT', url: 'wrapped.mp4' },
            { title: 'WSJ The Field', company: 'AT', url: 'wsj.mp4' },
            { title: 'Spotify Karol G', company: 'AT', url: 'https://flywithkarolg.atspotify.com/' },
            { title: 'Louis Vuitton 200 years', company: 'AT', url: 'https://en.louisvuitton.com/eng-nl/stories/louis-200' },
            { title: 'Spotify Made To Be Found', company: 'AT', url: 'https://found.byspotify.com/' },
            // { title: 'Spotify Journey of Discovery', company: 'AT', url: 'offline' },
        ].reverse();

        projects.forEach((project, i) => {
            project.index = i;
            project.videoWrapper = videoWrapper;
            Main.Projects.push(new Project(project, wrapper));
        });
        this.setTitleEvents(videoWrapper);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            wrapper.innerHTML = '<strong>Projects </strong>';

            Main.Projects.forEach((p, i) => {
                p.resize(projects[i], wrapper);
            });

            this.setTitleEvents(videoWrapper);
        });
    }

    setTitleEvents = (videoWrapper) => {
        let titles = Main.container.querySelectorAll('.video-url');
        let video = Main.container.querySelector('video');

        titles.forEach(title => {
            title.addEventListener('click', () => {
                videoWrapper.classList.add('visible');

                video.setAttribute('src', `../videos/${title.getAttribute('data-src')}`);
            });
        });
    }

    initAbout = _ => {
        const about = document.createElement('div');
        about.classList.add("about");
        about.innerHTML = `<strong>Dion Pieters</strong>, web developer at <a href="https://activetheory.net/home" target="_blank">Active Theory (AT)</a>, previously <a href="https://www.buildinamsterdam.com/cases" target="_blank">Build in Amsterdam (BiA)</a>.`

        Main.about = about;
        Main.container.appendChild(about);
    }

    initSocials = _ => {
        const socials = document.createElement('div');
        socials.classList.add("socials");

        ['LinkedIn', 'Instagram'].forEach((label, i) => {
            const social = document.createElement('a');
            social.setAttribute('href', i === 0 ? 'https://www.linkedin.com/in/dion-pieters-5433b37b/' : 'https://www.instagram.com/dion.gd/')
            social.setAttribute('target', '_blank');

            social.classList.add('social');
            social.innerHTML = label;

            socials.appendChild(social);
        });

        Main.socials = socials;
        Main.container.appendChild(socials);
    }

    /**
    * Render loop
    */
    loop = _ => {}

    /**
    * Event listeners
    */
}

new Main();